import React, { useState } from "react";

import { useSelector } from "react-redux";
import AddBetForm from "./AddBetForm";

const AddBetRates = ({ closeModal }) => {
  const { bet0 } = useSelector((state) => state.bet);
  const bet01 = [...bet0];
  const [betData, setBetData] = useState(bet01);

  // const { categoryBlock } = useSelector((state) => state.block);
  // if(categoryBlock){  
  //   console.log(categoryBlock);
  // }

  // Function to update a bet in the list
  const updateBets = (updatedBetData) => {
    setBetData(updatedBetData);
  };

  console.log(betData);
  return (
    <>
      <div>
        {/* <BetEditForm betData={betData} onUpdate={updateBets} /> */}
        <AddBetForm
          betData={betData}
          onUpdate={updateBets}
          closeModal={closeModal}
        />
      </div>
    </>
  );
};

export default AddBetRates;
