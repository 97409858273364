import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    categoryBlock : null,
}

const blockSlice = createSlice({
    name: 'block',
    initialState,
    reducers: {
        setBlock: (state, action) => {
            state.categoryBlock = action.payload;
        },
    },
});

export const { setBlock } = blockSlice.actions;

export default blockSlice.reducer;