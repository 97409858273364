import { apiSlice } from './apiSlice';
const USERS_URL = '/api/blocks';

// const ACCESS_TOKEN = JSON.parse(sessionStorage.getItem('token'))
// console.log(ACCESS_TOKEN)

export const blocksApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getBlocks: builder.query({
            query: (category) => ({
                url: `${USERS_URL}/data?category=${category}`,
                method: 'GET',
                credentials: 'include',
            })
        })
    })
})

export const { useGetBlocksQuery} = blocksApiSlice;