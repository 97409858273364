export const checkBetInCategoryBlock = (bet, categoryBlock) => {
    if (!categoryBlock) return false;
  
    const betTypeMapping = {
      '3t2': 'threeTopTwo',
      '3t1': 'threeTopOne',
      '3t0': 'threeTopZero',
      '2t1': 'twoTopOne',
      '2b1': 'twoBottomOne',
      '2b0': 'twoBottomZero'
    };
  
    const categoryBlockKey = betTypeMapping[bet.betType];
    if (!categoryBlockKey) return false;
  
    const categoryBlockArray = categoryBlock[categoryBlockKey];
    if (!categoryBlockArray) return false;
  
    return categoryBlockArray.includes(bet.betNum);
  };