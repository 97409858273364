import React, { useEffect, useState } from "react";
import { Table, Button, Container, Row, Col, Badge } from "react-bootstrap";
import "./BetEditForm.css";
import { useAddNewBetMutation } from "../slices/betsApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addBet2, clearBet } from "../slices/betSlice";
import { useTotalMutation } from "../slices/balancesApiSlice";
import { setBalance1 } from "../slices/balanceSlice";
import { sumBetAmount } from "./helper/HelperBet";
import { calculateTotalAmount } from "./helper/HelperBet";
import { getExpCategory } from "./helper/HelperTime";
import { Loader1 } from "./Loader1";
import { ToastContainer, toast } from "react-toastify";

import { checkBetInCategoryBlock } from "./helper/BetChecker";

function AddBetForm({ betData, onUpdate, closeModal }) {
  const { categoryBlock } = useSelector((state) => state.block);
  const betData1 = [...betData];
  // const [editedBetData, setEditedBetData] = useState([...betData1]);
  const [editedBetData, setEditedBetData] = useState(
    betData1.map((bet) => ({
      ...bet,
      isBlocked: checkBetInCategoryBlock(bet, categoryBlock),
      betAmount: checkBetInCategoryBlock(bet, categoryBlock)
        ? 0
        : bet.betAmount,
    }))
  );

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedData = [...editedBetData];
    console.log(index);
    updatedData[index] = { ...updatedData[index], [name]: value };
    setEditedBetData(updatedData);
  };

  const handleDelete = (betType, betNum) => {
    const updatedData = editedBetData.filter(
      (bet) => !(bet.betType === betType && bet.betNum === betNum)
    );
    setEditedBetData(updatedData);
  };

  const { categoryParams } = useParams();
  const dispatch = useDispatch();
  // console.log(categoryParams);
  // console.log(getExpCategory(categoryParams))
  const { userInfo } = useSelector((state) => state.auth);
  const [addNewBet, { isLoading }] = useAddNewBetMutation();

  const { balance1 } = useSelector((state) => state.balance0);
  // console.log(balance.balance);

  const [total] = useTotalMutation();

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const notify = () => toast.success("สั่งซื้อ หมายเลข เรียบร้อย!");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const betsToSubmit = editedBetData.filter(
      (bet) => !bet.isBlocked && bet.betAmount > 0
    );

    try {
      const res = await addNewBet({
        access_token: userInfo.token,
        phone: userInfo.phone,
        category: categoryParams,
        betMe: betsToSubmit,
        expDate: new Date(getExpCategory(categoryParams)),
      }).unwrap();

      onUpdate(editedBetData);
      dispatch(addBet2(res.newBet));
      dispatch(clearBet());
      notify();
      closeModal();
    } catch (err) {
      console.log(err);
    }

    try {
      //  console.log("hi")
      const res = await total().unwrap();

      dispatch(setBalance1({ ...res }));
    } catch (err) {
      console.log(err);
    }
  };

  // function sumBetAmounts(arrBet){
  //     let totalAmount = 0;
  //     for (let i =0;i<arrBet.length;i++){
  //         totalAmount += parseInt(arrBet[i].betAmount);
  //     }
  //     return totalAmount;
  // }

  const groupedBets = editedBetData.reduce((groups, bet) => {
    const betType = bet.betType;
    if (!groups[betType]) {
      groups[betType] = [];
    }
    groups[betType].push(bet);
    return groups;
  }, {});

  // fn return betType
  const arr = {
    "2t1": "2 บน ตรง",
    "2b1": "2 ล่าง ตรง",
    "2b0": "2 ล่าง วิ่ง",
    "3t2": "3 บน โต๊ด",
    "3t1": "3 บน ตรง",
    "3t0": "3 บน วิ่ง",
  };

  function getValueByKey(key) {
    return arr[key];
  }

  //   fn return multiple
  const arr1 = {
    "2t1": "x95",
    "2b1": "x95",
    "2b0": "x4",
    "3t2": "x120",
    "3t1": "x800",
    "3t0": "x3",
  };

  function getReturnByKey(key) {
    return arr1[key];
  }
  // console.log(balance.balance);
  // console.log(sumBetAmounts(editedBetData));

  useEffect(() => {
    let newNum =
      balance1 === null ? 0 : balance1.balance - sumBetAmount(editedBetData);
    // console.log(newNum);
    if (newNum <= 0) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
    // if(balance.balance - sumBetAmounts(editedBetData) >= 0){
    //   setButtonDisabled(false)
    //   console.log("Yesss")
    // }
  }, [editedBetData, balance1]);

  // Update sumBetAmount function to only count non-blocked bets
  // const calculateTotalAmount = (bets) => {
  //   return bets.reduce((total, bet) => !bet.isBlocked ? total + parseInt(bet.betAmount) : total, 0);
  // };

  return (
    <form>
      {Object.keys(groupedBets).map((betType) => (
        <div key={betType}>
          <h5>
            <Badge bg="warning" text="dark">{`Bet Type: ${getValueByKey(
              betType
            )}`}</Badge>
          </h5>
          <Table striped bordered hover responsive className="font13">
            <thead>
              <tr>
                <th className="table-percent10">
                  Item <br /> อันดับ
                </th>
                <th className="table-percent20">
                  Number
                  <br />
                  เลข{" "}
                </th>
                <th className="table-percent20">
                  Amount
                  <br />
                  จำนวน
                </th>
                <th className="table-percent20">
                  Rate
                  <br />
                  อัตราจ่าย
                </th>
                <th className="table-percent20">
                  Action
                  <br />
                  ลบ
                </th>
              </tr>
            </thead>
            <tbody>
              <ToastContainer />
              {groupedBets[betType].map((bet, index) => (
                <tr key={index} className={bet.isBlocked ? "blocked-bet" : ""}>
                  <td>{index + 1}</td>
                  <td>{bet.betNum}</td>
                  <td>
                    {bet.isBlocked ? (
                      <span className="blocked-message">
                        หมายเลขนี้ ถูกซื้อเต็มจำนวนแล้ว
                      </span>
                    ) : (
                      <input
                        className="table-percent100"
                        type="number"
                        name="betAmount"
                        value={bet.betAmount}
                        onChange={(e) =>
                          handleChange(e, editedBetData.indexOf(bet))
                        }
                      />
                    )}
                  </td>
                  <td>{getReturnByKey(betType)}</td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      disabled={bet.isBlocked}
                      onClick={() => handleDelete(bet.betType, bet.betNum)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ))}
      <Container className="text-center">
        <Row>
          <Col>
            <h5>
              <Badge bg="primary">Credit</Badge>
              <br />
              {balance1 ? balance1.balance : 0} ฿
            </h5>
          </Col>
          <Col>
            <h5>
              <Badge bg="primary">All Amount</Badge> <br />
              {calculateTotalAmount(editedBetData)} ฿
            </h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="danger" size="md" onClick={closeModal}>
              ยกเลิก
            </Button>
          </Col>
          <Col>
            <Button
              variant="success"
              size="md"
              onClick={handleSubmit}
              disabled={buttonDisabled}
            >
              {" "}
              {isLoading && <Loader1 />}แทงหวย
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            {userInfo.lastBalance <= 0 ? (
              <p className="red mt5">กรุณาเติมเงิน (please topup money)</p>
            ) : (
              ""
            )}{" "}
          </Col>
        </Row>
      </Container>
    </form>
  );
}

export default AddBetForm;
