import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import balanceReducer from './slices/balanceSlice';
import betReducer from './slices/betSlice';
import ownerReducer from './slices/ownerSlice';
import lottoReducer from './slices/lottoSlice';
import blockReducer from './slices/blockSlice';
import { apiSlice  } from './slices/apiSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        balance0: balanceReducer,
        bet: betReducer,
        owner: ownerReducer,
        lotto: lottoReducer,
        block: blockReducer,

        [apiSlice.reducerPath] : apiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true
});

export default store;