import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({ 

  baseUrl: 'https://api.ppf4app.com'
  // baseUrl: 'https://mm68-api.onrender.com'
  // baseUrl: 'http://localhost:5000'
  // Do not forget this
});

export const apiSlice = createApi({
  baseQuery,
  tagTypes: ['User','Balance','Bet','Owner','Lotto', 'Block'],
  endpoints: (builder) => ({}),
});