
export const sumBetAmount = (betMe) => {
  let sum = 0;

  for (let i = 0; i < betMe.length; i++) {
    sum += parseInt(betMe[i].betAmount);
  }
  return sum;
};

export const calculateTotalAmount = (bets) => {
  return bets.reduce((total, bet) => !bet.isBlocked ? total + parseInt(bet.betAmount) : total, 0);
};

// const arr3 = {
//   "2t1": "95",
//   "2b1": "95",
//   "2b0": "4",
//   "3t2": "120",
//   "3t1": "800",
//   "3t0": "3",
// };



export function calculateTotalBetAmount(betMe) {
  let totalAmount = 0;
  const arr3 = {
    "2t1": "95",
    "2b1": "95",
    "2b0": "4",
    "3t2": "120",
    "3t1": "800",
    "3t0": "3",
  };

  betMe.forEach((bet) => {
    if (bet.checkWin) {
      const betTypeValue = arr3[bet.betType];
      if (betTypeValue) {
        totalAmount += parseInt(betTypeValue) * bet.betAmount;
      }
    }
  });


  return totalAmount;
}


export const calculateWinAmount=(betInfo)=>{
  const arr3 = {
    "2t1": "95",
    "2b1": "95",
    "2b0": "4",
    "3t2": "120",
    "3t1": "800",
    "3t0": "3",
  };
  const { betType, betAmount} = betInfo;
  if(arr3.hasOwnProperty(betType)){
    const multiplier = parseFloat(arr3[betType]);
    const winAmount = betAmount * multiplier;
    return winAmount;
  }else{
    console.error(`Invalid betType: ${betType}`);
    return null;
  }
}



